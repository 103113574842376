@import './default';
@import './template';
@import './fontawesome-free-6.5.1-web/scss/fontawesome.scss';
@import './fontawesome-free-6.5.1-web/scss/solid.scss';


.tile {
    background-color: var(--light-70);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
}

.row {
    --bs-gutter-x: 1em;
    --bs-gutter-y: 1em;
}

canvas {
    max-height: 600px;
}

.card {
    border-radius: 0;
}

.card-title,
.card-title>* {
    margin-bottom: 0;
}

th,
td {
    padding: 0.5em;
    text-align: center;
}

th:first-child,
td:first-child {
    padding-left: 1em;
    text-align: left;
}


.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

thead {
    th:hover {
        opacity: 0.5;
        cursor: pointer;
        user-select: none;
    }

    th {
        svg {
            margin-left: 0.5em;
        }
    }
}



.table-responsive {
    min-height: 150px;
}

tbody {
    tr:hover {
        opacity: 0.5;
        cursor: pointer;
    }

    tr.tableForm:hover {
        opacity: 1;
        cursor: default;
    }

    tr.tableForm {
        td.action {
            min-width: 0;
            padding-right: 1em !important;
            width: 0;
        }
    }



    td {
        vertical-align: middle;
        // width: 150px;
        // max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

}

@for $i from 1 through 12 {
    .size#{$i} {
        table-layout: fixed;
        width: #{50 * $i}px !important;
        max-width: #{50 * $i}px !important;
        min-width: #{50 * $i}px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.pagination {
    .page-item {
        user-select: none;
    }

    .page-link:focus {
        z-index: 3;
        color: var(--bs-pagination-focus-color);
        background-color: var(--bs-pagination-bg);
        outline: 0;
        box-shadow: none;

        &:hover {
            z-index: 2;
            color: var(--bs-pagination-hover-color);
            background-color: var(--bs-pagination-hover-bg);
            border-color: var(--bs-pagination-hover-border-color);
        }
    }
}

.pagination-wrapper {
    padding: 0 1rem;

    * {
        user-select: none;
    }
}


.nav-pills .nav-link {
    border: 1px solid;
    margin-right: 1em;
}


.pagination {
    --bs-pagination-active-color: white;
    --bs-pagination-active-bg: var(--primary);
    --bs-pagination-active-border-color: var(--primary);
    --bs-pagination-color: var(--primary);
    --bs-pagination-hover-color: var(--primary);




    .page-link {
        border-radius: 0 !important;
    }
}


input[type="search"] {
    border-radius: 0;
    border: 1px solid var(--light-70);
    font-style: italic;

    &:focus {
        border-color: var(--primary);
        box-shadow: none;
    }
}

.btn {
    border-radius: 0;
}

.card {
    margin-bottom: 1em;
}

.dynamic-select-box {
    border: 1px solid var(--bs-border-color);

    .dynamic-select {
        max-height: 200px;
        overflow-y: auto;
        border-radius: 0;

        .form-check {
            padding: 0.5em;
            padding-left: 2em;
            border: 1px solid var(--bs-border-color);
            height: auto;
            box-sizing: content-box;
            margin: 0;

        }
    }
}

.list-actions {
    display: flex;

    >* {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        margin-right: .5em;

        &:last-child {
            margin-right: 0;
        }
    }
}


.autocompleteError {
    * {
        border-color: var(--danger) !important;
    }
}



.chartBox {
    width: 100%;
    height: 100%;
    min-height: 500px;
    position: relative;
    padding: 0;

    ul.chart-legend {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        flex-direction: row;
        border-bottom: 1px solid var(--bs-card-border-color);

        li {
            padding: .25em 1em;
            margin: 0 1px;
            list-style: none;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .chartWrapper {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        position: relative;
        width: 100%;
        height: 100%;

        .chart {
            min-width: #{300 * 6}px;
            min-height: 500px;

            //for 1 to 5
            @for $i from 1 through 6 {
                &.s#{$i} {
                    min-width: #{300 * $i}px;
                }
            }
        }
    }


    * {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
    }


    .chartOptions {
        position: absolute;
        top: -1px;
        min-width: 250px;
        left: -100vw;
        height: calc(100% + 2px);
        background-color: var(--light);
        border: 1px solid var(--bs-card-border-color);
        z-index: 3;

        .listHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;
            direction: ltr;
            padding: .5em 2em;

            span {
                cursor: pointer;
            }

            border-bottom: 1px solid var(--bs-card-border-color);
        }

        * {
            direction: rtl;
        }

        ul {
            max-height: 100%;
            margin: 0 !important;
            padding: 0 !important;
        }

        .toogleOptions {
            height: 3em;
            width: 3em;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: calc(100vw);
            right: unset;
            z-index: 333;
            cursor: pointer;
            background-color: white;
            border: 1px solid var(--bs-card-border-color);
            display: flex;
            justify-content: center;

            * {
                font-size: 1em;
            }
        }

        &.show {
            left: -1px;

            .toogleOptions {
                top: -1px;
                right: -3em;
                left: unset;
                background-color: white;
                -webkit-border-top-right-radius: .5em;
                -webkit-border-bottom-right-radius: .5em;
                -moz-border-radius-topleft: .5em;
                -moz-border-radius-bottomleft: .5em;
                border-top-right-radius: .5em;
                border-bottom-right-radius: .5em;
                border-left: none;
            }
        }
    }
}


.chart-footer {
    display: flex;
    flex-wrap: wrap;
    user-select: none;
    .chart-legend {
        color: var(--dark-70);
        margin-right: .5em;
        border: 1px solid var(--dark-70);
        padding: .1em .5em;
        margin-bottom: .5em;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }

        &.unselected {
            color: var(--dark-70);
            border-color: var(--dark-70);
            opacity: .6;
            text-decoration: line-through;
        }
    }
}

.inactive-selection {
    text-decoration: line-through !important;
    opacity: .4;
}


.cell-success {
    background-color: var(--success) !important;
    color: white !important;
}

.cell-danger {
    background-color: var(--danger) !important;
    color: white !important;
}

.filter {
    cursor: pointer;
    user-select: none;

    .form-control {
        border-radius: 0;
    }
}

table {
    th {
        white-space: nowrap;
    }
}